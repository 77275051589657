import { FIELDS } from "./fields"


export type FieldList = (keyof typeof FIELDS)[]
type IForm = {
  fields: FieldList
}

export const FORM:IForm = {
  fields: [
    'name',
    'company',
    'phone',
    'email',
    'message'
  ]
}